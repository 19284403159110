<template>
  <div>
    <div
      class="form--group photo--section row"
      :class="{ 'has-error': validation.hasError('listPhoto') }"
    >
      <div class="col-12 col-lg-3">
        <div class="d-flex align-items-center">
          <span>{{ $t('project.unitPhoto') }}</span>
          <span class="required">*</span>
        </div>
      </div>
      <div class="col-12 col-lg-6" style="position: relative">
        <section-loading :show="loading" />
        <!--ini preview gambar-->
        <div class="photo--preview-wrapper" v-show="listPhoto && listPhoto.length > 0">
          <photo-preview
            v-for="(photo, index) in listPhoto"
            :key="index"
            :index="index"
            :length="listPhoto.length"
            :photo="photo"
            :isFloorPlan="false"
            removePhotoDispatch="unitType/form/media/REMOVE_PHOTO"
          />
        </div>
        <vue-dropzone
          v-show="false"
          ref="photoDropzone"
          id="dropzone"
          :options="dropzoneOptions"
          @vdropzone-file-added="added"
          @vdropzone-sending="sending"
          @vdropzone-success="success"
          @vdropzone-complete="completed"
        />
        <v-btn
          type="button"
          color="primary"
          :disabled="listPhoto >= 15"
          class="btn btn-primary--light btn-block"
          @click="triggerDropzone"
        >
          {{ $t('project.upload') }}
        </v-btn>
        <span class="val-error" v-if="validation.hasError('listPhoto')">{{
          validation.firstError('listPhoto')
        }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import vue2Dropzone from 'nuxt-dropzone';
import { mapState } from 'vuex';
import { Validator } from 'simple-vue-validator';
const PhotoPreview = () =>
  import('@/components/project-management/unit-type/form/photo-preview.vue');
import SectionLoading from '@/components/content-loading/section-loading';

export default {
  name: 'photo-section',
  components: {
    vueDropzone: vue2Dropzone,
    PhotoPreview,
    SectionLoading,
  },
  validators: {
    listPhoto(value) {
      return Validator.value(value).custom(() => {
        if (value.length <= 0) {
          return this.$i18n.t('errors.general.photo.required');
        }
      });
    },
  },

  data() {
    return {
      addedPhoto: true,
      loading: false,
      dropzoneOptions: {
        url: `${this.$store.state.global.apiUrl}/api/file_content/upload_photo_w`,
        thumbnailWidth: 150,
        maxFilesize: 5,
        thumbnailMethod: 'contain',
        acceptedFiles: '.jpg,.png,.jpeg',
        // headers: {'X-CSRF-TOKEN': self.csrfToken},
        createImageThumbnails: true,
      },
    };
  },
  computed: {
    ...mapState({
      listPhoto: (state) => state.unitType.form.media.photoList,
      apiUrl: (state) => state.global.apiUrl,
      user: (state) => state.global.user,
    }),
  },
  methods: {
    validate() {
      return this.$validate().then(
        function (success) {
          return !!success;
        }.bind(this),
      );
    },
    triggerDropzone() {
      this.$refs.photoDropzone.dropzone.hiddenFileInput.click();
    },
    completed() {
      this.loading = false;
    },
    added(file) {
      this.loading = true;
      const dropzone = this.$refs.photoDropzone;
      const maxFile = 15;
      const listPhoto = this.listPhoto.length;
      const uploading = dropzone.getUploadingFiles().length;
      const queued = dropzone.getQueuedFiles().length;
      const totalPhoto = listPhoto + uploading + queued;
      this.addedPhoto = true;
      if (totalPhoto >= maxFile) {
        dropzone.removeFile(file);
        // eslint-disable-next-line no-undef
        this.loading = false;
        this.$swal(
          this.$t('errors.general.photo.maxTitle'),
          this.$i18n.t('errors.general.photo.maxMessage', {
            max: maxFile,
          }),
          'error',
        );
      }
    },
    // eslint-disable-next-line no-unused-vars
    sending(file, xhr, formData) {
      this.loading = true;
      xhr.setRequestHeader('Authorization', `Bearer ${this.$store.state.global.token}`);
    },
    success(file, response) {
      let img = file.previewElement.querySelector('img');
      this.$store.commit('unitType/form/media/ADD_PHOTO', {
        src: img.src,
        uuid: response.data.photo_uuid,
        isPrimary: false,
      });
      let dropzone = this.$refs.photoDropzone;
      dropzone.removeFile(file);
    },
  },
};
</script>

<style scoped></style>
